import Signin from "./views/auth/Signin";
import ForgotPassword from "./views/auth/ForgotPassword";
import ResetPassword from "./views/auth/ResetPassword";
import NotFoundView from "./views/NotFoundView";

import Admin from "./views/admin/Admin";
import AdminCompany from "./views/admin/AdminCompany";

import HomeExpert from "./views/dashboard-expert/Home";
import DataExpert from "./views/dashboard-expert/Data";
import ManagementExpert from "./views/dashboard-expert/Management";

import HomeRecycler from "./views/dashboard-recycler/Home";
import PartsRecycler from "./views/dashboard-recycler/Parts";
import ImportRecycler from "./views/dashboard-recycler/Import";
import PrepareRecycler from "./views/dashboard-recycler/Prepare";
import APIRecycler from "./views/dashboard-recycler/API";

import HomeInsurance from "./views/dashboard-insurance/Home";

import { URLS } from "./configs/URLS";
import { RouteType } from "./types/routesType";

const windowWidth = window.innerWidth;

const defaultRoutes: RouteType[] = [
  {
    path: URLS.forgot,
    element: <ForgotPassword isMobile={windowWidth < 950} />,
    layout: "auth",
    isAuth: true,
  },
  {
    path: URLS.resetPassword,
    element: <ResetPassword isMobile={windowWidth < 950} />,
    layout: "auth",
    isAuth: true,
  },
  {
    path: URLS.signin,
    element: <Signin isMobile={windowWidth < 950} />,
    layout: "auth",
    isAuth: true,
  },
  {
    path: "*",
    element: <Signin isMobile={windowWidth < 950} />,
    layout: "auth",
    isAuth: true,
  },
];

const expertRoutes: RouteType[] = [
  {
    title: "Accueil",
    path: URLS.home,
    element: <HomeExpert />,
    layout: "dashboard",
    type: "Expert",
  },
  {
    title: "Données",
    path: URLS.data,
    element: <DataExpert />,
    layout: "dashboard",
    type: "Expert",
  },
  {
    title: "Pilotage",
    path: URLS.management,
    element: <ManagementExpert />,
    layout: "dashboard",
    type: "Expert",
  },
];

const recyclerRoutes: RouteType[] = [
  {
    title: "Accueil",
    path: URLS.home,
    element: <HomeRecycler />,
    layout: "dashboard",
    type: "Épaviste",
  },
  {
    title: "Liste des pièces",
    path: URLS.parts,
    element: <PartsRecycler />,
    layout: "dashboard",
    type: "Épaviste",
  },
  {
    title: "Importer des données",
    path: URLS.import,
    element: <ImportRecycler />,
    layout: "dashboard",
    type: "Épaviste",
  },
  {
    title: "Péparer mon bilan",
    path: URLS.prepare,
    element: <PrepareRecycler />,
    layout: "dashboard",
    type: "Épaviste",
  },
  {
    title: "Documentation API",
    path: URLS.api,
    element: <APIRecycler />,
    layout: "dashboard",
    type: "Épaviste",
  },
];

const insuranceRoutes: RouteType[] = [
  {
    title: "Accueil",
    path: URLS.home,
    element: <HomeInsurance />,
    layout: "dashboard",
    type: "Assureur",
  },
];

const adminRoutes: RouteType[] = [
  {
    path: URLS.admin,
    element: <Admin />,
    layout: "dashboard",
    isRole: true,
  },
  {
    path: URLS.adminCompany,
    element: <AdminCompany />,
    layout: "dashboard",
    isRole: true,
  },
];

export const routes: RouteType[] = [
  ...defaultRoutes,
  ...expertRoutes,
  ...recyclerRoutes,
  ...insuranceRoutes,
  ...adminRoutes,
  {
    path: "*",
    element: <NotFoundView />,
  },
];
