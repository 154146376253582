import { useEffect } from "react";
import { Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";

import VerticalLineChart from "../../components/dashboard/VerticalLineChart";
import PieChart from "../../components/dashboard/PieChart";
import VerticalBarChart from "../../components/dashboard/VerticalBarChart";
import GeneralCard from "../../components/dashboard/GeneralCard";

import { useAppDispatch } from "../../reducers";

import { getDashboardRecycler } from "../../actions/dataActions";

import { TbaseReducer } from "../../types/reducersType";

function Home() {
  const dispatch = useAppDispatch();

  const { dashboardDataRecycler } = useSelector(
    (state: TbaseReducer) => state.dataReducer
  );

  useEffect(() => {
    dispatch(getDashboardRecycler());
  }, [dispatch]);

  return (
    <>
      <Row
        style={{
          rowGap: 30,
          padding: 15,
        }}
      >
        <Col xs={12}>
          <h2 className="mt-0 mb-0">Accueil</h2>
        </Col>
        <Col xs={12} lg={4}>
          <GeneralCard
            title="Nombre moyen de pièces prélevées par véhicule"
            value={
              dashboardDataRecycler ? dashboardDataRecycler.averageParts : 0
            }
            label=""
            subValue={
              dashboardDataRecycler ? dashboardDataRecycler.totalParts : 0
            }
            subLabel="Nombre total de pièces prélevées"
            isFirst
          />
        </Col>
        <Col xs={12} lg={4}>
          <GeneralCard
            title="Poids moyen des pièces"
            value={
              dashboardDataRecycler ? dashboardDataRecycler.averageWeight : 0
            }
            label="kg"
            subValue={
              dashboardDataRecycler ? dashboardDataRecycler.totalWeight : 0
            }
            subLabel="kg - Poids cumulé des pièces"
          />
        </Col>
        <Col xs={12} lg={4}>
          <GeneralCard
            title="Émissions de CO2e moyennes évitées"
            value={
              dashboardDataRecycler
                ? dashboardDataRecycler.averageEscapeEmission
                : 0
            }
            label="CO2e "
            subValue={
              dashboardDataRecycler
                ? dashboardDataRecycler.totalEscapeEmission
                : 0
            }
            subLabel="CO2e - Émissions de CO2e cumulées évitées"
          />
        </Col>
        <Col xs={12} lg={4}>
          <Row
            style={{
              rowGap: 30,
              height: "100%",
            }}
          >
            <Col xs={12} lg={12}>
              <GeneralCard
                title="Stock de pièces"
                value={0}
                label=""
                subValue={0}
                subLabel=""
              />
            </Col>
            <Col xs={12} lg={12}>
              <GeneralCard
                title="Pièces écoulées"
                value={0}
                label=""
                subValue={0}
                subLabel=""
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <PieChart
            title="Répartition des matériaux"
            data={dashboardDataRecycler ? dashboardDataRecycler.materials : []}
          />
        </Col>
        <Col xs={12} lg={4}>
          <PieChart
            title="Répartition des pièces"
            data={dashboardDataRecycler ? dashboardDataRecycler.parts : []}
          />
        </Col>
        <Col xs={12} lg={6}>
          <VerticalLineChart
            title="Nombre moyen de pièces prélevées par véhicule"
            data={
              dashboardDataRecycler
                ? dashboardDataRecycler.partsVehiclesMonths
                : []
            }
            label="Pièces"
          />
        </Col>
        <Col xs={12} lg={6}>
          <VerticalLineChart
            title="Nombre total de pièces prélevées par véhicule"
            data={
              dashboardDataRecycler
                ? dashboardDataRecycler.partsVehiclesMonths.map((element) => ({
                    name: element.name,
                    value: element.total || 0,
                  }))
                : []
            }
            label="Pièces"
          />
        </Col>
        <Col xs={12} md={12} lg={5}>
          <VerticalBarChart
            title="Répartition des marques"
            data={dashboardDataRecycler ? dashboardDataRecycler.brands : []}
            label="Pièces"
          />
        </Col>
        <Col xs={12} md={12} lg={7}>
          <VerticalBarChart
            title="répartition des modèles"
            data={dashboardDataRecycler ? dashboardDataRecycler.models : []}
            label="Pièces"
          />
        </Col>
      </Row>
    </>
  );
}

export default Home;
