import { useEffect } from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal, Loader } from "@sahadevia/ui-kit-modern-sahadevia";

import { routes } from "./routes";

import AuthLayout from "./layouts/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";

import { useAppDispatch } from "./reducers";

import { refreshToken } from "./actions/authAction";

import { TbaseReducer } from "./types/reducersType";

function App() {
  const dispatch = useAppDispatch();

  const { isAuthenticated, userId, companyInfo, role } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const { isLoad } = useSelector((state: TbaseReducer) => state.loaderReducer);

  useEffect(() => {
    if (!userId) {
      dispatch(refreshToken());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(refreshToken());
    }
  }, [isAuthenticated, dispatch]);

  return (
    <>
      <HashRouter>
        <Routes>
          {role && <Route path="/" element={<Navigate to="/admin" replace />} />}
          {routes
            .filter(
              (route) =>
                (role ? route.isRole : !route.isRole) &&
                ((!isAuthenticated && route.isAuth) ||
                  (isAuthenticated &&
                    !route.isAuth &&
                    (route.type === companyInfo.type || !route.type)))
            )
            .map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  route.layout === "dashboard" ? (
                    <DashboardLayout>{route.element}</DashboardLayout>
                  ) : route.layout === "auth" ? (
                    <AuthLayout>{route.element}</AuthLayout>
                  ) : (
                    route.element
                  )
                }
              />
            ))}
        </Routes>
      </HashRouter>
      {isLoad ? (
        <Modal target="root">
          <Loader />
        </Modal>
      ) : null}
    </>
  );
}

export default App;
