import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import React from "react";
import { Col } from "react-grid-system";
import { getNumber } from "../../utils/general";

interface CardHomeProps {
  title: string;
  currentValue: number;
  oldValue: number;
}

const CardHome: React.FC<CardHomeProps> = ({
  title,
  currentValue,
  oldValue,
}) => {
  return (
    <Col xs={12} md={6} lg={3}>
      <Card
        className="h-100 g-0"
        style={{
          backgroundColor: "var(--white)",
          border: "none",
        }}
        classNameChildren="h-100"
      >
        <div
          className="h-100"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <p style={{ margin: 0, color: "#000000", fontWeight: "bold" }}>
            {title}
          </p>
          <h2
            style={{
              margin: 0,
              marginTop: "auto",
              marginBottom: "auto",
              fontSize: 40,
              color: currentValue > 0 ? "#00A86B" : "#FF0000",
            }}
          >
            {getNumber(currentValue)} <span style={{ fontSize: 30 }}>kg</span>
          </h2>
          <p style={{ margin: 0, marginTop: 0, color: "#000000" }}>
            <span
              style={{
                color: oldValue > 0 ? "#00A86B" : "#FF0000",
              }}
            >
              {getNumber(oldValue)}
            </span>{" "}
            kg par rapport au mois dernier
          </p>
        </div>
      </Card>
    </Col>
  );
};

export default CardHome;
