import { Row, Col } from "react-grid-system";
import { Card, Button, InputText, Flex } from "@sahadevia/ui-kit-modern-sahadevia";
import { TUser } from "../../types/admin/adminType";

type UserFormProps = {
  user: Partial<TUser>;
  setUser: (user: Partial<TUser>) => void;
  onClose: () => void;
  onAdd: () => void;
  onUpdate: () => void;
};

function UserForm({ user, setUser, onClose, onAdd, onUpdate }: UserFormProps) {
  return (
    <Card title="Ajouter un utilisateur">
      <Row style={{ rowGap: 10 }}>
        <Col xs={12} md={4}>
          <InputText
            placeholder="E-mail"
            value={user.userEmail}
            onChange={(e) => setUser({ ...user, userEmail: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputText
            placeholder="Nom"
            value={user.userName}
            onChange={(e) => setUser({ ...user, userName: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputText
            placeholder="Téléphone"
            value={user.userPhone}
            onChange={(e) => setUser({ ...user, userPhone: e.target.value })}
          />
        </Col>
        <Col xs={12}>
          <Flex gap={10} justify="end">
            <Button text="Fermer" outline onClick={onClose} />
            <Button
              text="Rénitialiser"
              outline
              onClick={() =>
                setUser({
                  ...{},
                  userEmail: "",
                  userName: "",
                  userPhone: "",
                })
              }
            />
            <Button text={user.userId ? "Modifier" : "Ajouter"} onClick={user.userId ? onUpdate : onAdd} />
          </Flex>
        </Col>
      </Row>
    </Card>
  );
}

export default UserForm;
