import { Button } from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";
import { TUser } from "../../types/admin/adminType";

type UserTableProps = {
  users: TUser[];
  setUser: (user: Partial<TUser>) => void;
  setIsModalOpen: (isOpen: boolean) => void;
};

function UserTable({ users, setUser, setIsModalOpen }: UserTableProps) {
  return (
    <table className="dashboard-table">
      <thead>
        <tr>
          <th>Nom</th>
          <th>E-mail</th>
          <th>Dernière connexion</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, idx) => (
          <tr key={idx}>
            <td>{user.userName}</td>
            <td>{user.userEmail}</td>
            <td>
              {user.userDateSignin
                ? moment(user.userDateSignin).format("DD/MM/YYYY HH:mm:ss")
                : null}
            </td>
            <td>
              <Button
                text="Modifier"
                style={{ width: "100%" }}
                onClick={() => {
                  setUser(user);
                  setIsModalOpen(true);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default UserTable;
