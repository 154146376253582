import { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import { Button, Modal } from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { getCompanies, addCompany, updateCompany } from "../../actions/adminAction";

import { TbaseReducer } from "../../types/reducersType";
import { TCompany } from "../../types/admin/adminType";

import CompanyForm from "../../components/admin/CompanyForm";
import CompanyTable from "../../components/admin/CompanyTable";

function Admin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [company, setCompany] = useState<Partial<TCompany>>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { companies } = useSelector((state: TbaseReducer) => state.adminReducer);

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  const _addCompany = () => {
    if (
      company.companyName &&
      company.companyPhone &&
      company.companyEmail &&
      company.companyAddress1 &&
      company.companyAddress2 &&
      company.companyCity &&
      company.companyZipCode &&
      company.companyType &&
      company.companySiret &&
      company.companyTva &&
      company.companyCapital
    ) {
      dispatch(
        addCompany(
          company.companyName,
          company.companyPhone,
          company.companyEmail,
          company.companyAddress1,
          company.companyAddress2,
          company.companyCity,
          company.companyZipCode,
          company.companyType,
          company.companySiret,
          company.companyTva,
          company.companyCapital
        )
      );
      setIsModalOpen(false);
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  const _updateCompany = () => {
    if (
      company.companyId &&
      company.companyName &&
      company.companyPhone &&
      company.companyEmail &&
      company.companyAddress1 &&
      company.companyAddress2 &&
      company.companyCity &&
      company.companyZipCode &&
      company.companyType &&
      company.companySiret &&
      company.companyTva &&
      company.companyCapital
    ) {
      dispatch(
        updateCompany(
          company.companyId,
          company.companyName,
          company.companyPhone,
          company.companyEmail,
          company.companyAddress1,
          company.companyAddress2,
          company.companyCity,
          company.companyZipCode,
          company.companyType,
          company.companySiret,
          company.companyTva,
          company.companyCapital
        )
      );
      setIsModalOpen(false);
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  return (
    <Row style={{ rowGap: 30, padding: 15 }}>
      <Col xs={12}>
        <h2 className="mt-0 mb-0">Administration - gestion des organisations</h2>
      </Col>
      <Col xs={12}>
        <Button text="Ajouter une organisation" onClick={() => setIsModalOpen(true)} />
      </Col>
      <Col xs={12}>
        <CompanyTable companies={companies} setCompany={setCompany} setIsModalOpen={setIsModalOpen} navigate={navigate} />
      </Col>
      {isModalOpen && (
        <Modal target="root">
          <CompanyForm
            company={company}
            setCompany={setCompany}
            onClose={() => setIsModalOpen(false)}
            onAdd={_addCompany}
            onUpdate={_updateCompany}
          />
        </Modal>
      )}
    </Row>
  );
}

export default Admin;
