import React, { JSX } from "react";
import { Sidebar } from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import icon from "../images/CRI.svg";

import { TbaseReducer } from "../types/reducersType";

import { signout } from "../actions/authAction";

import { useAppDispatch } from "../reducers";

import { routes } from "../routes";

type DashboardLayoutProps = {
  children: JSX.Element;
};

function DashboardLayout({ children }: DashboardLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { companyInfo, role } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  return (
    <div className="Dashboard-container">
      <div
        className="Dashboard-container-body active-shadow"
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
        }}
      >
        <Sidebar
          dropdownData={{
            email: "contact@test.fr",
            grade: "Administrateur",
            onProfilClick: () => {},
            onSignoutClick: () => dispatch(signout()),
          }}
          userIcon=""
          userIconText={"AZ"}
          logo={icon}
          primary={
            role
              ? [
                  {
                    text: "Administration",
                    active: location.pathname.endsWith("/admin"),
                    onClick: () => navigate("/admin"),
                  },
                ]
              : routes
                  .filter(
                    (route) => route.title && route.type === companyInfo.type
                  )
                  .map((route) => ({
                    text: route.title || "",
                    active: location.pathname.endsWith(route.path),
                    onClick: () => navigate(route.path),
                  }))
          }
          secondary={[]}
          footer={[]}
          children={
            <div
              className="p-20"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "calc(100% - 75px)",
                padding: 16,
                gap: 16,
                backgroundColor: "var(--active-sidebar-color)",
              }}
            >
              {children}
            </div>
          }
        />
      </div>
    </div>
  );
}

export default DashboardLayout;
