import { Flex, Button } from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";
import { TCompany } from "../../types/admin/adminType";

type CompanyTableProps = {
  companies: TCompany[];
  setCompany: (company: Partial<TCompany>) => void;
  setIsModalOpen: (isOpen: boolean) => void;
  navigate: (path: string) => void;
};

function CompanyTable({ companies, setCompany, setIsModalOpen, navigate }: CompanyTableProps) {
  return (
    <div className="overflow-x-auto">
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Type</th>
            <th>Nb. utilisateurs</th>
            <th>Adresse</th>
            <th>Code postal</th>
            <th>Ville</th>
            <th>SIRET</th>
            <th>Constante PRE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company, idx) => (
            <tr key={idx}>
              <td>{company.companyName}</td>
              <td>{company.companyType}</td>
              <td>{company.userCount}</td>
              <td>
                {company.companyAddress1} {company.companyAddress2}
              </td>
              <td>{company.companyZipCode}</td>
              <td>{company.companyCity}</td>
              <td>{company.companySiret}</td>
              <td>{company.companyReusePart[moment().format("YYYY")]}</td>
              <td>
                <Flex gap={10} justify="end">
                  <Button
                    text="Modifier"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setCompany(company);
                      setIsModalOpen(true);
                    }}
                  />
                  <Button
                    text="Entrer"
                    style={{ width: "100%" }}
                    onClick={() => {
                      navigate(company.companyId.toString());
                    }}
                  />
                </Flex>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CompanyTable;
