import { Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button } from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";

import { useAppDispatch } from "../../reducers";

import {
  countDataPartsRecycler,
  getDataPartsRecycler,
} from "../../actions/dataActions";

import { TbaseReducer } from "../../types/reducersType";

import { getNumber, getPercentage } from "../../utils/general";
import ReactPaginate from "react-paginate";

function Parts() {
  const dispatch = useAppDispatch();

  const { countPartsRecycler, dataPartsRecycler } = useSelector(
    (state: TbaseReducer) => state.dataReducer
  );

  const [currentPage, setCurrentPage] = useState(0);

  const pageLength = 20;
  const pageCount: number = Math.ceil(countPartsRecycler / pageLength);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    dispatch(getDataPartsRecycler(pageLength, selectedItem.selected));
  };

  useEffect(() => {
    dispatch(countDataPartsRecycler());
  }, [dispatch]);

  useEffect(() => {
    if (countPartsRecycler) {
      dispatch(getDataPartsRecycler(pageLength, 0));
    }
  }, [dispatch, countPartsRecycler]);

  return (
    <Row
      style={{
        rowGap: 30,
        padding: 15,
      }}
    >
      <Col xs={12}>
        <h2 className="mt-0 mb-0">Liste des pièces</h2>
      </Col>
      <Col xs={12}>
        <Button
          text="Télécharger les données"
          onClick={() => {
            const fileUrl = `${window.location.origin.replace(
              "3002",
              "5002"
            )}/api/file/download-recycler-parts`; // Remplace par l'URL de ton fichier
            const link = document.createElement("a");
            link.href = fileUrl;
            link.setAttribute(
              "download",
              `${moment().format("YYYY_MM_DD-recycler_parts.xlsx")}`
            ); // Optionnel, pour forcer le téléchargement et définir le nom du fichier
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        />
      </Col>
      <Col xs={12}>
        <div className="overflow-x-auto">
          {/* Table */}
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Marque</th>
                <th>Modèle</th>
                <th>Nom</th>
                <th>Référence</th>
                <th>Poids (kg)</th>
                <th>PRE (CO2e)</th>
                <th>Échange (CO2e)</th>
                <th>Gain CO2e (%)</th>
              </tr>
            </thead>
            <tbody>
              {dataPartsRecycler.map((part, idx) => (
                <tr key={idx}>
                  <td>{currentPage * pageLength + idx + 1}</td>
                  <td>{part.brand}</td>
                  <td>{part.model}</td>
                  <td>{part.name}</td>
                  <td>{part.reference}</td>
                  <td>{getNumber(part.weight)}</td>
                  <td>{getNumber(part.PRE)}</td>
                  <td>{getNumber(part.change)}</td>
                  <td>{getPercentage(100 - (part.PRE * 100) / part.change)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination controls */}
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </Col>
    </Row>
  );
}

export default Parts;
