import { Row, Col } from "react-grid-system";
import { Card, Button, InputText, Flex, InputSelect } from "@sahadevia/ui-kit-modern-sahadevia";
import { TCompany } from "../../types/admin/adminType";

type CompanyFormProps = {
  company: Partial<TCompany>;
  setCompany: (company: Partial<TCompany>) => void;
  onClose: () => void;
  onAdd: () => void;
  onUpdate: () => void;
};

function CompanyForm({ company, setCompany, onClose, onAdd, onUpdate }: CompanyFormProps) {
  return (
    <Card title="Ajouter une organisation">
      <Row style={{ rowGap: 10 }}>
        <Col xs={12} md={4}>
          <InputText
            placeholder="Nom"
            value={company.companyName}
            onChange={(e) => setCompany({ ...company, companyName: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputText
            placeholder="Téléphone"
            value={company.companyPhone}
            onChange={(e) => setCompany({ ...company, companyPhone: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputText
            placeholder="E-mail"
            value={company.companyEmail}
            onChange={(e) => setCompany({ ...company, companyEmail: e.target.value })}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputText
            placeholder="Adresse 1"
            value={company.companyAddress1}
            onChange={(e) => setCompany({ ...company, companyAddress1: e.target.value })}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputText
            placeholder="Adresse 2"
            value={company.companyAddress2}
            onChange={(e) => setCompany({ ...company, companyAddress2: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputText
            placeholder="Code postal"
            value={company.companyZipCode}
            onChange={(e) => setCompany({ ...company, companyZipCode: e.target.value })}
          />
        </Col>
        <Col xs={12} md={8}>
          <InputText
            placeholder="Ville"
            value={company.companyCity}
            onChange={(e) => setCompany({ ...company, companyCity: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputText
            placeholder="SIRET"
            value={company.companySiret}
            onChange={(e) => setCompany({ ...company, companySiret: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputText
            placeholder="n° TVA"
            value={company.companyTva}
            onChange={(e) => setCompany({ ...company, companyTva: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputText
            placeholder="Capital"
            value={company.companyCapital}
            onChange={(e) => setCompany({ ...company, companyCapital: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <InputSelect
            placeholder="Type"
            options={[
              { value: "Assureur", text: "Assureur" },
              { value: "Épaviste", text: "Épaviste" },
              { value: "Expert", text: "Expert" },
            ]}
            value={company.companyType || ""}
            onChange={(value) => setCompany({ ...company, companyType: value.toString() })}
          />
        </Col>
        <Col xs={12}>
          <Flex gap={10} justify="end">
            <Button text="Fermer" outline onClick={onClose} />
            <Button
              text="Rénitialiser"
              outline
              onClick={() =>
                setCompany({
                  ...{},
                  companyName: "",
                  companyPhone: "",
                  companyEmail: "",
                  companyAddress1: "",
                  companyAddress2: "",
                  companyCity: "",
                  companyZipCode: "",
                  companyType: "",
                  companySiret: "",
                  companyTva: "",
                  companyCapital: "",
                })
              }
            />
            <Button text={company.companyId ? "Modifier" : "Ajouter"} onClick={company.companyId ? onUpdate : onAdd} />
          </Flex>
        </Col>
      </Row>
    </Card>
  );
}

export default CompanyForm;
