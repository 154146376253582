import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { Row, Col } from "react-grid-system";
import CardHome from "../../components/dashboard/CardHome";
import CardGlobal from "../../components/dashboard/CardGlobal";
import DoughnutChart from "../../components/dashboard/DoughnutChart";
import LineChart from "../../components/dashboard/LineChart";

function Home() {
  return (
    <>
      <Row
        style={{
          rowGap: 30,
          padding: 15,
        }}
      >
        <Col xs={12}>
          <h2 className="mt-0 mb-0">Accueil</h2>
        </Col>
        <CardHome title="Gain moyen CO2e PRE" currentValue={1} oldValue={1} />
        <CardHome title="Gain cumul CO2e PRE" currentValue={1} oldValue={-1} />
        <CardHome
          title="Gain moyen CO2e réparation pièces"
          currentValue={1}
          oldValue={1}
        />
        <CardHome
          title="Gain cumul CO2e réparation pièces"
          currentValue={-1}
          oldValue={1}
        />
        <CardGlobal
          title="Émission de CO2e globale"
          currentValue={1}
          oldValue={1}
        />
        <DoughnutChart
          detailsEmission={[
            {
              value: 1,
              average: 0.5,
              name: "CO2e PRE",
              color: "#FFBB28",
              icon: "",
            },
            {
              value: 1,
              average: 0.5,
              name: "CO2e réparation pièces",
              color: "#FF8042",
              icon: "",
            },
            {
              value: 1,
              average: 0.5,
              name: "CO2e réparation main d'oeuvre",
              color: "#0088FE",
              icon: "",
            },
            {
              value: 1,
              average: 0.5,
              name: "CO2e réparation peinture",
              color: "#00C49F",
              icon: "",
            },
            {
              value: 1,
              average: 0.5,
              name: "CO2e réparation consommables",
              color: "#FFBB28",
              icon: "",
            },
            {
              value: 1,
              average: 0.5,
              name: "CO2e réparation déchets",
              color: "#FF8042",
              icon: "",
            },
          ]}
          total={10}
        />
        <LineChart
          title="test"
          data={{
            labels: [
              "Janvier",
              "Février",
              "Mars",
              "Avril",
              "Mai",
              "Juin",
              "Juillet",
            ],
            datasets: [
              {
                label: "CO2e PRE",
                data: [1, 2, 3, 4, 5, 6, 7],
                borderColor: "#FFBB28",
                backgroundColor: "#FFBB28",
              },
              {
                label: "CO2e réparation pièces",
                data: [1, 2, 3, 4, 5, 6, 7],
                borderColor: "#FF8042",
                backgroundColor: "#FF8042",
              },
            ],
          }}
        />
        <LineChart
          title="test"
          data={{
            labels: [
              "Janvier",
              "Février",
              "Mars",
              "Avril",
              "Mai",
              "Juin",
              "Juillet",
            ],
            datasets: [
              {
                label: "CO2e PRE",
                data: [1, 2, 3, 4, 5, 6, 7],
                borderColor: "#FFBB28",
                backgroundColor: "#FFBB28",
              },
              {
                label: "CO2e réparation pièces",
                data: [1, 2, 3, 4, 5, 6, 7],
                borderColor: "#FF8042",
                backgroundColor: "#FF8042",
              },
            ],
          }}
        />
      </Row>
    </>
  );
}

export default Home;
