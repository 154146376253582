import { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import { Button, Modal } from "@sahadevia/ui-kit-modern-sahadevia";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { getUsers, addUser, updateUser } from "../../actions/adminAction";

import { useAppDispatch } from "../../reducers";

import { TbaseReducer } from "../../types/reducersType";
import { TUser } from "../../types/admin/adminType";

import UserForm from "../../components/admin/UserForm";
import UserTable from "../../components/admin/UserTable";

function AdminCompany() {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const [user, setUser] = useState<Partial<TUser>>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { users } = useSelector((state: TbaseReducer) => state.adminReducer);

  useEffect(() => {
    if (companyId) {
      dispatch(getUsers(parseInt(companyId)));
    }
  }, [dispatch, companyId]);

  const _addUser = () => {
    if (companyId && user.userEmail && user.userName && user.userPhone) {
      dispatch(addUser(companyId, user.userEmail, user.userName, user.userPhone));
      setIsModalOpen(false);
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  const _updateUser = () => {
    if (user.userId && user.userEmail && user.userName && user.userPhone) {
      dispatch(updateUser(user.userId, user.userEmail, user.userName, user.userPhone));
      setIsModalOpen(false);
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  return (
    <Row style={{ rowGap: 30, padding: 15 }}>
      <Col xs={12}>
        <h2 className="mt-0 mb-0">Administration - {companyId}</h2>
      </Col>
      <Col xs={12}>
        <Button text="Ajouter un utilisateur" onClick={() => setIsModalOpen(true)} />
      </Col>
      <Col xs={12}>
        <UserTable users={users} setUser={setUser} setIsModalOpen={setIsModalOpen} />
      </Col>
      {isModalOpen && (
        <Modal target="root">
          <UserForm
            user={user}
            setUser={setUser}
            onClose={() => setIsModalOpen(false)}
            onAdd={_addUser}
            onUpdate={_updateUser}
          />
        </Modal>
      )}
    </Row>
  );
}

export default AdminCompany;
