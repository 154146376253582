import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Col } from "react-grid-system";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { getNumber, getPercentage } from "../../utils/general";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  detailsEmission: detailsEmission[];
  total: number;
}

type detailsEmission = {
  value: number;
  average: number;
  name: string;
  color: string;
  icon: string;
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  detailsEmission,
  total,
}) => {
  const chartData = {
    labels: detailsEmission.map((detail) => detail.name),
    datasets: [
      {
        data: detailsEmission.map((detail) => detail.value),
        backgroundColor: detailsEmission.map((detail) => detail.color),
        hoverBackgroundColor: detailsEmission.map((detail) => detail.color),
      },
    ],
  };

  return (
    <Col xs={12} md={12} lg={5}>
      <Card
        className="h-100 g-0"
        style={{
          backgroundColor: "var(--white)",
          border: "none",
        }}
        classNameChildren="h-100"
      >
        <>
          <p
            style={{
              margin: 0,
              marginBottom: 10,
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            Détail des émissions de CO2e
          </p>
          <div
            style={{
              display: "flex",
              position: "relative",
              color: "var(--black)",
              minHeight: "85px",
              overflow: "hidden",
            }}
          >
            <div style={{ flex: 1 }}>
              <Doughnut
                data={chartData}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
            <div className="d-flex flex-column" style={{ gap: 10 }}>
              {detailsEmission.map((element, idx) => (
                <div
                  key={idx}
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    color: "#535374",
                    fontSize: "13px",
                  }}
                >
                  <div style={{ marginRight: 12 }}>
                    <img
                      src={element.icon}
                      width={24}
                      style={{ maxHeight: 30 }}
                      alt=""
                    />
                  </div>
                  <div style={{ flexDirection: "column", display: "flex" }}>
                    <span style={{ fontWeight: "bold", alignItems: "center" }}>
                      {getPercentage((element.value * 100) / total)} |{" "}
                      {getNumber(element.average, 2)} kg
                    </span>
                    <span style={{ whiteSpace: "nowrap" }}>{element.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      </Card>
    </Col>
  );
};

export default DoughnutChart;
