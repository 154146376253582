import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import React from "react";
import { Col } from "react-grid-system";
import { getNumber } from "../../utils/general";
import { FaPlane, FaGlobe } from "react-icons/fa";

interface CardGlobalProps {
  title: string;
  currentValue: number;
  oldValue: number;
}

const CardGlobal: React.FC<CardGlobalProps> = ({
  title,
  currentValue,
  oldValue,
}) => {
  return (
    <Col xs={12} md={12} lg={7}>
      <Card
        className="h-100 g-0"
        style={{
          backgroundColor: "var(--white)",
          border: "none",
        }}
        classNameChildren="h-100"
      >
        <div
          className="h-100"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <p style={{ margin: 0, color: "#000000", fontWeight: "bold" }}>
            {title}
          </p>
          <h2 style={{ margin: 0, fontSize: 50 }}>{currentValue}</h2>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <FaGlobe />{" "}
            <span>Équivaut à 138,12 tour(s) du monde en voiture</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <FaPlane />{" "}
            <span>Équivaut à 599,82 aller-retour Paris/New-York en avion</span>
          </div>
          <h3 style={{ margin: 0 }}>67,21 kg (moyenne / dossier)</h3>
          <p style={{ margin: 0, color: oldValue > 0 ? "#FF0000" : "#00A86B" }}>
            {oldValue} % par rapport au mois dernier (moyenne / dossier)
          </p>
        </div>
      </Card>
    </Col>
  );
};

export default CardGlobal;
