import { Row, Col } from "react-grid-system";
import { useEffect } from "react";

import { useAppDispatch } from "../../reducers";

import DashboardTable from "../../components/dashboard/DashboardTable";

import { getTable } from "../../actions/dataActions";

function Management() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTable());
  }, [dispatch]);

  return (
    <>
      <Row
        style={{
          rowGap: 30,
          padding: 15,
        }}
      >
        <Col xs={12}>
          <h2 className="mt-0 mb-0">Pilotage</h2>
        </Col>
        <Col xs={12}>
          <DashboardTable />
        </Col>
      </Row>
    </>
  );
}

export default Management;
