import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import { Col } from "react-grid-system";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

interface LineChartProps {
  title: string;
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
    }[];
  };
}

const LineChart: React.FC<LineChartProps> = ({ title, data }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: false,
      },
    },
  };

  return (
    <Col xs={12} md={12} lg={6}>
      <Card
        className="h-100 g-0"
        style={{
          backgroundColor: "var(--white)",
          border: "none",
        }}
        classNameChildren="h-100"
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                marginBottom: 10,
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {title}
            </p>
            <div
              style={{
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {[
                {
                  name: "test1",
                  color: "red",
                },
                {
                  name: "test2",
                  color: "green",
                },
              ].map((element, idx) => (
                <div
                  key={idx}
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    color: "#535374",
                    fontSize: "13px",
                  }}
                >
                  <div
                    className="mr-5"
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: element.color,
                    }}
                  />
                  <span>{element.name}</span>
                </div>
              ))}
            </div>
          </div>
          <Line data={data} options={options} />
        </>
      </Card>
    </Col>
  );
};

export default LineChart;
