import React, { useEffect, useState } from "react";
import { Button } from "@sahadevia/ui-kit-modern-sahadevia";
import { Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";

import { useAppDispatch } from "../../reducers";

import {
  countDataRecycler,
  getDataRecycler,
  importDataRecycler,
} from "../../actions/dataActions";

import { TbaseReducer } from "../../types/reducersType";

function Import() {
  const dispatch = useAppDispatch();

  const { countRecycler, dataRecycler } = useSelector(
    (state: TbaseReducer) => state.dataReducer
  );

  const [currentPage, setCurrentPage] = useState(0);

  const pageLength = 20;
  const pageCount: number = Math.ceil(countRecycler / pageLength);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    dispatch(getDataRecycler(pageLength, selectedItem.selected));
  };

  useEffect(() => {
    dispatch(countDataRecycler());
  }, [dispatch]);

  useEffect(() => {
    if (countRecycler) {
      dispatch(getDataRecycler(pageLength, 0));
    }
  }, [dispatch, countRecycler]);

  const [csvContent, setCsvContent] = useState("");

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && file.name.endsWith(".csv")) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const content = e.target?.result as string;
        setCsvContent(content);
      };

      // Lis le fichier comme texte
      reader.readAsText(file);
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  const triggerFileUpload = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput?.click();
  };

  useEffect(() => {
    if (csvContent) {
      dispatch(importDataRecycler(csvContent));
    }
  }, [dispatch, csvContent]);

  return (
    <Row
      style={{
        rowGap: 30,
        padding: 15,
      }}
    >
      <Col xs={12}>
        <h2 className="mt-0 mb-0">Importer des données</h2>
      </Col>
      <Col xs={12}>
        <div className="d-flex" style={{ gap: 10 }}>
          <Button
            text="Télécharger le fichier type"
            onClick={() => {
              const blob = new Blob(
                [
                  `"brand","model","registration date","fuel","car weight","gearbox","part name","part reference","dismantling date","sale date"`,
                ],
                { type: "text/csv" }
              );
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.setAttribute("href", url);
              link.setAttribute("download", "template-import.csv");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            }}
          />
          <Button text="Importer des données" onClick={triggerFileUpload} />
          <input
            type="file"
            id="fileInput"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
        </div>
      </Col>
      <Col xs={12}>
        <div className="overflow-x-auto">
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Date de l'import</th>
                <th>Nombre de pièces</th>
                <th>Nombre d'anomalie</th>
                <th>État</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataRecycler.map((element, idx) => (
                <tr key={idx}>
                  <td>{currentPage * pageLength + idx + 1}</td>
                  <td>
                    {moment(element.dateInsert).format("DD/MM/YYYY HH:mm")}
                  </td>
                  <td>{element.count}</td>
                  <td>{element.error}</td>
                  <td>{element.status ? "Terminée" : "En cours"}</td>
                  <td>
                    <Button text="Télécharger la source" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination controls */}
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </Col>
    </Row>
  );
}

export default Import;
